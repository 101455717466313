import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  // HOME
  {
    path: '/',
    name: 'index',
    component: () => import('../views/Index.vue'),
    meta: {
      authRequired: true,
      guestRequired: false,
      schamanEmployeeRequired: false,
      insights: {
        sectionName: 'Index',
        pageName: 'Index',
      }
    },
  },





  // KNOWLEDGE BASE
  {
    path: '/knowledge-base',
    name: 'knowledge-base',
    component: () => import('../views/KnowledgeBase/KnowledgeBase.vue'),
    meta: {
      authRequired: true,
      guestRequired: false,
      schamanEmployeeRequired: false,
      insights: {
        sectionName: 'KnowledgeBase',
        pageName: 'Index',
      }
    },
  },

  {
    path: '/knowledge-base/:id',
    component: () => import('../views/KnowledgeBase/KnowledgeBase.vue'),
    meta: {
      authRequired: true,
      guestRequired: false,
      schamanEmployeeRequired: false,
      insights: {
        sectionName: 'KnowledgeBaseCategory',
        pageName: 'Category',
      }
    },
  },

  {
    path: '/knowledge-base-article/:id',
    component: () => import('../views/KnowledgeBase/KnowledgeBaseArticle.vue'),
    meta: {
      authRequired: true,
      guestRequired: false,
      schamanEmployeeRequired: false,
      insights: {
        sectionName: 'KnowledgeBase',
        pageName: 'Article',
      }
    },
  },






  // RELEASES
  {
    path: '/releases',
    component: () => import('../views/Releases/Releases.vue'),
    meta: {
      authRequired: true,
      guestRequired: false,
      schamanEmployeeRequired: false,
    },
    children: [
      {
        path: '/',
        component: () => import('../views/Releases/ReleasesHome.vue'),
        name: 'Releases',
        meta: {
          authRequired: true,
          guestRequired: false,
          schamanEmployeeRequired: false,
          insights: {
            sectionName: 'WhatsNew',
            pageName: 'Index',
          }
        },
      },
      {
        path: 'changelog',
        component: () => import('../views/Releases/Changelog.vue'),
        name: 'ReleasesChangelog',
        meta: {
          authRequired: true,
          guestRequired: false,
          schamanEmployeeRequired: false,
          insights: {
            sectionName: 'Releases',
            pageName: 'Index',
          }
        },
      },
      {
        path: 'changelog/:id',
        name: 'ReleasesChangelogArticle',
        component: () => import('../views/Releases/ChangelogArticle.vue'),
        meta: {
          authRequired: true,
          guestRequired: false,
          schamanEmployeeRequired: false,
          insights: {
            sectionName: 'Releases',
            pageName: 'Article',
          }
        },
      },
      {
        path: 'hightlights',
        name: 'ReleasesHightlightsArticle',
        component: () => import('../views/Releases/HightlightsArticle.vue'),
        meta: {
          authRequired: true,
          guestRequired: false,
          schamanEmployeeRequired: false,
          insights: {
            sectionName: 'Hightlights',
            pageName: 'Article',
          }
        },
      },
    ],
  },






  // LEARNING PATHS
  {
    path: '/learning-paths',
    name: 'learning-paths',
    component: () => import('../views/LearningPaths/LearningPaths.vue'),
    meta: {
      authRequired: true,
      guestRequired: false,
      schamanEmployeeRequired: false,
      insights: {
        sectionName: 'LearningPath',
        pageName: 'Index',
      }
    },
  },

  {
    path: '/learning-paths/:id',
    component: () => import('../views/LearningPaths/LearningPathsArticle.vue'),
    meta: {
      authRequired: true,
      guestRequired: false,
      schamanEmployeeRequired: false,
      insights: {
        sectionName: 'LearningPath',
        pageName: 'Article',
      }
    },
  },






  // UPGRADE HUB
  {
    path: '/upgrade-hub',
    name: 'upgrade-hub',
    component: () => import('../views/UpgradeHub/UpgradeHub'),
    meta: {
      authRequired: true,
      guestRequired: false,
      schamanEmployeeRequired: true,
      insights: {
        sectionName: 'UpgradeHub',
        pageName: 'Index',
      }
    },
  },
  




  
  // LOGIIN
  {
    path: '/auth',
    component: () => import('../views/UserAuth/UserAuth.vue'),
    meta: {
      authRequired: false,
      guestRequired: true,
      schamanEmployeeRequired: false,
      insights: {
        sectionName: 'Auth',
        pageName: 'Index',
      }
    },
  },

  {
    path: '/forgot-password',
    component: () => import('../views/UserAuth/ForgotPassword.vue'),
    meta: {
      authRequired: false,
      guestRequired: true,
      schamanEmployeeRequired: false,
      insights: {
        sectionName: 'ForgotPassword',
        pageName: 'Article',
      }
    },
  },





  // Catch all
  { path: '/:pathMatch(.*)', redirect: '/' }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


router.beforeEach(async (to, from, next) => {
  const user = await store.dispatch('checkUserStatus');

  // If no user and auth is required
  if (to.meta && to.meta.authRequired && !user) {
    return next(`/auth?redirectUrl=${to.fullPath}`);
  }

  // If user and guest is required
  if (to.meta && to.meta.guestRequired && user) {
    return next(`/`);
  }

  // If the user is not a Schaman employee and requires to be one, he/she will be redirected to the home page
  if (to.meta && to.meta.authRequired && !to.meta.guestRequired && user && to.meta.schamanEmployeeRequired) {
    const isEmployeeOfSchaman = user?.email?.trim().toLowerCase()?.endsWith('schaman.com')
    if (!isEmployeeOfSchaman)
      return next(`/`);
  }



  next();
});


export default router;

import Vue from 'vue';
import Vuex from 'vuex';
import firebase from '@/firebase';
import {cloneDeep} from 'lodash';
import {amplitude} from '@/amplitude';

function objectToArrayElements(data) {
	return Object.keys(data || {}).map(id => ({
		id,
		...data[id]
	}));
}

function parseReleaseNotesWithDetails(releaseNotes) {
	return releaseNotes.map(releaseNote => {
		const itemsArray = objectToArrayElements(releaseNote.items);
		const feedbackArray = itemsArray?.flatMap(item => objectToArrayElements(item.feedback))
		const qualityTicketsArray = objectToArrayElements(releaseNote.quality_tickets);

		return {
			...releaseNote,
			items: itemsArray || [],
			quality_tickets: qualityTicketsArray || [],
			feedback: feedbackArray || []
		};
	});
}

Vue.use(Vuex);
export default new Vuex.Store({
	state: {
		// Operational
		loading: false,

		project_id: process.env.VUE_APP_FIREBASE_PROJECTID,
		NODE_ENV: process.env.NODE_ENV,


		// Auth
		userAuth: {
			uid: null
		},
		userClaims: {},
		permissionGroups: ['Everyone'], // Array of permission groups the user has, so far we have "Everyone" (default), "Schaman" and "Product"

		articles_preview: [],
		article_categories: [],

		learning_paths: [],
		learning_path_modules: [],

		settings: {
			current_version: '',
			current_year: '',
			images: {
				releases: {
					all_release_image: '',
					highlights_image: '',
					latest_version_image: ''
				}
			}
		},

		releaseNotes: [],
		versions: []
	},

	getters: {
		userAuth: state => {
			return state.userAuth;
		},

		userClaims: state => {
			return state.userClaims;
		},

		signedIn: _state => {
			return Boolean(_state.userAuth.uid);
		},

		releaseNotes: state => {
			return state.releaseNotes
		},

		versions: state => {
			return state.versions
		}
	},

	mutations: {
		logout(_state, _user) {
			_state.userAuth = {uid: null};
		},

		setLoading(_state, _loading) {
			_state.loading = _loading;
		},

		setUserAuth(_state, _user) {
			_state.userAuth = cloneDeep(_user);
		},

		setPermissionGroups(_state, _userAuth) {
			_state.permissionGroups = ['Everyone'];

			// Product
			if (['carlino.gonzalez@schaman.com', 'marta.illan@schaman.com', 'eduardo.belda@schaman.com'].includes(_userAuth.email)) {
				_state.permissionGroups.push('Product');
			}

			// Product
			if (_userAuth.email.endsWith('@schaman.com')) {
				_state.permissionGroups.push('Schaman');
			}
		},

		setUserClaims(_state, _claims) {
			_state.userClaims = cloneDeep(_claims);
		},

		setArticles(_state, _articles) {
			_state.articles_preview = cloneDeep(Object.entries(_articles).map(_art => ({
				id: _art[0],
				data: _art[1]
			}))).filter(_art => _state.permissionGroups.includes(_art.data.visibility || 'Everyone'));
		},

		setLearningPaths(_state, _learning_paths) {
			// Only the ones with visibility
			_state.learning_paths = cloneDeep(Object.entries(_learning_paths).map(_lp => ({
				id: _lp[0],
				data: _lp[1]
			}))).filter(_lp => _state.permissionGroups.includes(_lp.data.visibility));
		},

		setLearningPathModules(_state, _learning_path_modules) {
			_state.learning_path_modules = cloneDeep(Object.entries(_learning_path_modules).map(_lp => ({
				id: _lp[0],
				data: _lp[1]
			})));
		},

		setCategories(_state, categories) {
			_state.article_categories = cloneDeep(categories);
		},

		setSettings(_state, settings) {
			_state.settings = settings;
		},

		setReleaseNotes(_state, releaseNotes) {
			_state.releaseNotes = releaseNotes
		},

		setVersions(_state, releaseNotes) {
			_state.versions = Object.values(releaseNotes).map(el => el.numeric_version).filter(el => el).sort()
		}
	},

	actions: {
		login({commit, state}, {email, password}) {
			return new Promise((resolve, reject) => {
				firebase
					.auth()
					.signInWithEmailAndPassword(email, password)
					.then(userAuth => {
						commit('setUserAuth', userAuth.user);
						commit('setPermissionGroups', userAuth.user);
						resolve(userAuth);

						// Analytics
						amplitude.track('Logged In');
					})
					.catch(err => {
						reject(err)
					})
			})
		},

		logout({commit, state}) {
			return new Promise((resolve, reject) => {
				firebase
					.auth()
					.signOut()
					.then(() => {
						// Analytics
						amplitude.track('Logged Out');

						setTimeout(() => {
							amplitude.reset();

							commit('logout');
							resolve();
						}, 1000);
					})
					.catch(err => {
						reject(err)
					})
			})
		},

		checkUserStatus({commit, state}) {
			return new Promise((resolve, reject) => {
				firebase.auth().onAuthStateChanged(userAuth => {
					if (userAuth) {
						commit('setUserAuth', userAuth);
						commit('setPermissionGroups', userAuth);
						resolve(userAuth)
					} else {
						resolve(userAuth)
					}
				})
			})
		},

		checkUserClaims({commit, state}, force = false) {
			return new Promise((resolve, reject) => {
				firebase
					.auth()
					.currentUser.getIdTokenResult(force)
					.then(result => {
						commit('setUserClaims', result.claims)
						resolve(result.claims)
					})
					.catch(() => {
						reject()
					})
			})
		},

		getArticlesPreview({commit, state}) {
			return new Promise((resolve, reject) => {
				firebase.database().ref('/academy/articles/preview').get().then(data => {
					commit('setArticles', data.val());
					resolve(data.val());
				}).catch(err => {
					reject(err);
				});
			});
		},

		getLearningPaths({commit, state}) {
			return new Promise((resolve, reject) => {
				firebase.database().ref('/academy/learning_paths').get().then(data => {
					commit('setLearningPaths', data.val());
					resolve(data.val());
				}).catch(err => {
					reject(err);
				});
			});
		},

		getLearningPathModules({commit, state}) {
			return new Promise((resolve, reject) => {
				firebase.database().ref('/academy/learning_path_modules').get().then(data => {
					commit('setLearningPathModules', data.val());
					resolve(data.val());
				}).catch(err => {
					reject(err);
				});
			});
		},

		async getAcademySettings({commit, state}) {
			let raw_settings = (await firebase.database().ref('/academy/settings').get()).val();

			// Categories
			let categories = Object.entries(raw_settings.categories).map(c => ({
				id: c[0], data: {
					...c[1],
					subcategories: Object.entries(c[1].subcategories).map(sc => ({
						id: sc[0],
						data: sc[1]
					})).sort((a, b) => a.data.order - b.data.order)
				}
			})).sort((a, b) => a.data.order - b.data.order);
			commit('setCategories', categories);

			// Current year && current version
			commit('setSettings', {
				current_version: raw_settings.current_version,
				current_year: raw_settings.current_year,
				images: raw_settings.images
			});
		},

		getReleaseNotes({commit, state}) {
			return new Promise((resolve, reject) => {
				firebase.database().ref('/academy/release_notes/preview').get().then(async data => {

					const releaseNotesWithDetails = await this.dispatch('joinFullData', Object.values(data.val()));
					const parsedReleaseNotesWithDetails = parseReleaseNotesWithDetails(releaseNotesWithDetails);
					commit('setReleaseNotes', parsedReleaseNotesWithDetails);
					commit('setVersions', data.val());
					resolve(data.val());
				}).catch(err => {
					reject(err);
				});
			});
		},

		getCurrentBreakingChangesFull({commit, state}, {id}) {
			return new Promise((resolve, reject) => {
				firebase.database().ref('/academy/breaking_changes/full/' + id).get().then(data => {
					resolve(data.val());
				}).catch(err => {
					reject(err);
				});
			});
		},

		getCurrentBreakingChangesPreview({commit, state}, {id}) {
			return new Promise((resolve, reject) => {
				firebase.database().ref('/academy/breaking_changes/preview/' + id).get().then(data => {
					resolve(data.val());
				}).catch(err => {
					reject(err);
				});
			});
		},

		async joinFullData({commit, state}, releaseNotes) {
			return await Promise.all(
				releaseNotes.map(async (releaseNote) => {
					const breakingChangesIds = releaseNote.breaking_changes;

					if (!breakingChangesIds || breakingChangesIds.length === 0) {
						return releaseNote;
					}

					const breaking_changes = await Promise.all(
						breakingChangesIds.map(async (id) => {
							const full = await this.dispatch('getCurrentBreakingChangesFull', {id});
							const preview = await this.dispatch('getCurrentBreakingChangesPreview', {id});

							return full || preview ? {notion_content: full?.data, ...preview} : undefined;
						})
					);

					return {...releaseNote, breaking_changes: breaking_changes.filter(bc => !!bc)};
				})
			);
		},
	}
});
